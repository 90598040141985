import { useState, useEffect } from "react";
import Image from "next/image.js";

import SearchTab from "../SearchTab/SearchTab.jsx";
import ExploreButton from "../ExploreButton/ExploreButton.jsx";
import { translate } from "@/utils";

import headerImagDesktop from "@/assets/home_header.png";
import headerImagMobile from "@/assets/hero-bg-mob.webp";

const HeroHeader = () => {
  const [screenHeight, setScreenHeight] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window?.innerHeight - 300);
    };

    // Set initial height
    handleResize();

    // Add event listener
    window?.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => {
      window?.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section
      className="md:relative flex flex-col gap-8 md:flex-row items-center py-16 px-8 w-full min-h-[580px] md:min-h-full"
      style={{
        height: "696px",
      }}
    >
      <div className="z-[1] md:w-3/4">
        <h1 className="text-white text-left font-semibold mb-4 text-3xl sm-text-5xl lg:text-6xl">
          {translate("heroTitle")}
        </h1>
        <p className="text-white text-left text-lg lg:text-xl">
          {translate("heroSubTitle")}
        </p>

        <div className="my-6">
          <ExploreButton label="exploreProperties" />
        </div>
      </div>

      <Image
        src={headerImagDesktop}
        alt="hero home desktop"
        fill
        sizes="(max-width: 516px) 100vw, (max-width: 1440px) 50vw, 1440px"
        placeholder="blur"
        priority
        className="blur-[1px] brightness-[.75] object-cover hidden md:block rounded-3xl"
      />
      <Image
        src={headerImagMobile}
        alt="hero home desktop"
        fill
        sizes="(max-width: 516px) 100vw, (max-width: 1440px) 50vw, 1440px"
        placeholder="blur"
        priority
        className="blur-[1px] brightness-[.75] object-cover w-full md:hidden"
      />

      <SearchTab getCategories={""} />
    </section>
  );
};

export default HeroHeader;

import Link from "next/link";
import { translate } from "@/utils";

export default function ExploreButton({ label }) {
  return (
    <Link
      href="/properties/all-properties"
      target="_blank noreferer noopiner"
      className="z-50 inline-flex items-center justify-center  font-semibold rounded-full px-6 py-2 shadow-lg bg-white pr-3"
    >
      <span className="mr-2 text-black text-sm">{translate(label)}</span>
      <div className="bg-white p-1 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-5 w-5 text-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </div>
    </Link>
  );
}

"use client";
import React, { useEffect, useState, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import { useSelector } from "react-redux";
import ExploreButton from "../ExploreButton/ExploreButton.jsx";

// Import 3th party packeges
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper/modules";

// Import Utils
import {
  GetFeturedListingsApi,
  getUserRecommendationApi,
  GetCitiesApi,
} from "@/store/actions/campaign";
import { store } from "@/store/store";
import { languageData } from "@/store/reducer/languageSlice";
import { silderCacheData } from "@/store/reducer/momentSlice";
import { translate } from "@/utils";

// Import Dynamic Components
import Layout from "../Layout/Layout";
import LoginModal from "../LoginModal/LoginModal";

import MobileHeadline from "../MobileHeadlines/MobileHeadline";
import VerticalCard from "../Cards/VerticleCard";
import VerticalCardSkeleton from "../Skeleton/VerticalCardSkeleton";
import UserRecommendationCard from "../Cards/UserRecommendationCard";
import HeroHeader from "./HeroHeader";
import { BsArrowRight } from "react-icons/bs";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "aos/dist/aos.css";

const HomePage = () => {
  const lang = useSelector(languageData);
  useEffect(() => {}, [lang]);

  const [isLoading, setIsLoading] = useState(true);

  const [nearbyCityData, setnearbyCityData] = useState();

  const [getMostViewedProp, setGetMostViewedProp] = useState();
  const [getMostFavProperties, setGetMostFavProperties] = useState();
  const [userRecommendationData, setUserRecommendationData] = useState();
  const [cities, setCities] = useState();

  const isLoggedIn = useSelector((state) => state.User_signup);
  const userCurrentId =
    isLoggedIn && isLoggedIn?.data ? isLoggedIn?.data?.data?.id : null;

  const language = store.getState().Language.languages;
  const sliderdata = useSelector(silderCacheData);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const breakpoints = {
    0: {
      slidesPerView: 1,
    },
    375: {
      slidesPerView: 1.25,
      spaceBetween: 20,
    },
    576: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    992: {
      slidesPerView: 2.5,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 3.5,
    },
    1400: {
      slidesPerView: 3.5,
    },
    1600: {
      slidesPerView: 3.5,
    },
  };

  useEffect(() => {
    setIsLoading(true);

    GetFeturedListingsApi({
      top_rated: "2",
      current_user: isLoggedIn ? userCurrentId : "",
      onSuccess: (response) => {
        const MostViewed = response.data;
        setIsLoading(false);
        setGetMostViewedProp(MostViewed);
      },
      onError: (error) => {
        console.log(error);
        setIsLoading(true);
      },
    });
  }, [isLoggedIn]);

  useEffect(() => {
    setIsLoading(true);

    GetFeturedListingsApi({
      most_liked: "1",
      current_user: isLoggedIn ? userCurrentId : "",
      onSuccess: (response) => {
        const MostFav = response.data;

        setIsLoading(false);
        setGetMostFavProperties(MostFav);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && userCurrentId) {
      getUserRecommendationApi({
        onSuccess: (res) => {
          setUserRecommendationData(res.data);
        },
        onError: (err) => {
          console.log(err);
        },
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    GetCitiesApi(
      (response) => {
        const citiesData = response.data;
        setCities(citiesData);
      },
      (error) => {
        console.log(error);
      },
    );
  }, []);

  useEffect(() => {}, [nearbyCityData, userRecommendationData]);

  const swiperRef1 = useRef(null);
  const swiperRef2 = useRef(null);
  const swiperRef3 = useRef(null);

  useEffect(() => {
    if (!swiperRef1.current) return;

    const swiper = swiperRef1.current;

    const updateNavigation = () => {
      const prevButton = document.querySelector(".sw_prev_1");
      const nextButton = document.querySelector(".sw_next_1");

      if (swiper.isBeginning) {
        prevButton.classList.add("swiper-button-disabled");
      } else {
        prevButton.classList.remove("swiper-button-disabled");
      }

      if (swiper.isEnd) {
        nextButton.classList.add("swiper-button-disabled");
      } else {
        nextButton.classList.remove("swiper-button-disabled");
      }
    };

    swiper.on("init", updateNavigation);
    swiper.on("slideChange", updateNavigation);

    updateNavigation();

    return () => {
      if (swiper) {
        swiper.off("init", updateNavigation);
        swiper.off("slideChange", updateNavigation);
      }
    };
  }, []);

  useEffect(() => {
    if (!swiperRef2.current) return;

    const swiper = swiperRef2.current;

    const updateNavigation = () => {
      const prevButton = document.querySelector(".sw_prev_2");
      const nextButton = document.querySelector(".sw_next_2");

      if (swiper.isBeginning) {
        prevButton.classList.add("swiper-button-disabled");
      } else {
        prevButton.classList.remove("swiper-button-disabled");
      }

      if (swiper.isEnd) {
        nextButton.classList.add("swiper-button-disabled");
      } else {
        nextButton.classList.remove("swiper-button-disabled");
      }
    };

    swiper.on("init", updateNavigation);
    swiper.on("slideChange", updateNavigation);

    updateNavigation();

    return () => {
      if (swiper) {
        swiper.off("init", updateNavigation);
        swiper.off("slideChange", updateNavigation);
      }
    };
  }, []);

  useEffect(() => {
    if (!swiperRef3.current) return;

    const swiper = swiperRef3.current;

    const updateNavigation = () => {
      const prevButton = document.querySelector(".sw_prev_3");
      const nextButton = document.querySelector(".sw_next_3");

      if (swiper.isBeginning) {
        prevButton.classList.add("swiper-button-disabled");
      } else {
        prevButton.classList.remove("swiper-button-disabled");
      }

      if (swiper.isEnd) {
        nextButton.classList.add("swiper-button-disabled");
      } else {
        nextButton.classList.remove("swiper-button-disabled");
      }
    };

    // Add event listeners
    swiper.on("init", updateNavigation);
    swiper.on("slideChange", updateNavigation);

    updateNavigation();

    return () => {
      if (swiper) {
        swiper.off("init", updateNavigation);
        swiper.off("slideChange", updateNavigation);
      }
    };
  }, []);

  return (
    <Layout stikyNav={true}>
      {/* HERO HEADER SECTION  */}
      <div className="container">
        <HeroHeader />
      </div>
      <div style={{ marginTop: sliderdata.length > 0 ? "0" : "0px" }}>
        {getMostViewedProp && getMostViewedProp?.length > 0 ? (
          <section id="main_properties">
            <div className="container">
              <div className="most_fav_header">
                <div className="mb-0 h3 font-bold">
                  {translate("recommendedProperties")}
                </div>
                <div className="position-relative d-flex justify-content-center swiper-navigation-out d-none d-md-flex">
                  <div
                    className="swiper-button-prev sw_prev_1"
                    onClick={() => swiperRef1.current.slidePrev()}
                  />
                  <div
                    className="swiper-button-next sw_next_1"
                    onClick={() => swiperRef1.current.slideNext()}
                  />
                </div>
              </div>
              <div className="mobile-headline-view">
                <div className="text-center h3">
                  {translate("recommendedProperties")}
                </div>
                <div className="position-relative d-flex justify-content-center swiper-navigation-out d-none d-md-flex">
                  <div
                    className="swiper-button-prev sw_prev_1"
                    onClick={() => swiperRef1.current.slidePrev()}
                  />
                  <div
                    className="swiper-button-next sw_next_1"
                    onClick={() => swiperRef1.current.slideNext()}
                  />
                </div>
              </div>
              <div
                id="most-view-properties"
                dir={language.rtl === "1" ? "rtl" : "ltr"}
              >
                <Swiper
                  onSwiper={(setSwiper) => (swiperRef1.current = setSwiper)}
                  slidesPerView={3.5}
                  spaceBetween={30}
                  freeMode={false}
                  navigation={{
                    prevEl: ".swiper-button-prev.sw_prev_1",
                    nextEl: ".swiper-button-next.sw_next_1",
                  }}
                  onInit={(swiper) => {
                    swiperRef1.current = swiper;
                  }}
                  pagination={true}
                  modules={[FreeMode, Pagination, Navigation]}
                  className="most-view-swiper"
                  breakpoints={breakpoints}
                >
                  {isLoading ? (
                    // Show skeleton loading when data is being fetched
                    <Swiper
                      dir={language.rtl === "1" ? "rtl" : "ltr"}
                      slidesPerView={3.5}
                      spaceBetween={30}
                      freeMode={false}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[FreeMode, Pagination, Navigation]}
                      className="most-view-swiper"
                      breakpoints={breakpoints}
                    >
                      {Array.from({ length: 6 }).map((_, index) => (
                        <SwiperSlide>
                          <div className="loading_data">
                            <VerticalCardSkeleton />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    getMostFavProperties?.map((ele, index) => (
                      <SwiperSlide id="most-view-swiper-slider" key={index}>
                        <Link
                          target="_blank"
                          href="/properties-details/[slug]"
                          as={`/properties-details/${ele.slug_id}`}
                          passHref
                        >
                          <VerticalCard ele={ele} />
                        </Link>
                      </SwiperSlide>
                    ))
                  )}
                </Swiper>
              </div>
            </div>
          </section>
        ) : null}

        <div className="my-6 w-auto flex justify-center">
          <ExploreButton label="exploreProperties" />
        </div>

        {/* user recommendation section  */}
        {userRecommendationData && userRecommendationData?.length > 0 ? (
          <section id="personalize_feed">
            <div className="container">
              <div className="personalize_feed_header">
                <div>
                  <h3>
                    <span className="highlight">
                      {translate("personalize")}
                    </span>
                    <span>
                      <span>{translate("feed")}</span>
                    </span>
                  </h3>
                </div>
                <div className="rightside_personalize_feed">
                  <Link href="/all-personalized-feeds">
                    <button className="learn-more" id="viewall">
                      <span aria-hidden="true" className="circle">
                        <div className="icon_div">
                          <span className="icon arrow">
                            <BsArrowRight />
                          </span>
                        </div>
                      </span>
                      <span className="button-text">
                        {translate("seeAllProp")}
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="mobile-headline-view">
                <MobileHeadline
                  data={{
                    start: translate("personalize"),
                    center: translate("feed"),
                    link: "/all-personalized-feeds",
                  }}
                />
              </div>
              <div
                id="personalize_feed_properties"
                dir={language.rtl === "1" ? "rtl" : "ltr"}
              >
                <Swiper
                  slidesPerView={3.5}
                  spaceBetween={30}
                  freeMode={false}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[FreeMode, Pagination]}
                  className="personalize_feed_swiper"
                  breakpoints={breakpoints}
                >
                  {isLoading ? (
                    // Show skeleton loading when data is being fetched
                    <Swiper
                      dir={language.rtl === "1" ? "rtl" : "ltr"}
                      slidesPerView={3.5}
                      spaceBetween={30}
                      freeMode={false}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[FreeMode, Pagination]}
                      className="personalize_feed_swiper"
                      breakpoints={breakpoints}
                    >
                      {Array.from({ length: 6 }).map((_, index) => (
                        <SwiperSlide>
                          <div className="loading_data">
                            <VerticalCardSkeleton />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    userRecommendationData?.map((ele, index) => (
                      <SwiperSlide id="most-view-swiper-slider" key={index}>
                        <Link
                          href="/properties-details/[slug]"
                          as={`/properties-details/${ele.slug_id}`}
                          passHref
                        >
                          <UserRecommendationCard ele={ele} />
                        </Link>
                      </SwiperSlide>
                    ))
                  )}
                </Swiper>
              </div>
            </div>
          </section>
        ) : null}

        {/* ===== BEST PLACES ON EACH CITY =======  */}
        {!isLoading && (
          <section id="best_place">
            <div className="container">
              <div className="most_fav_header">
                <p className="mb-0 h3 font-bold">
                  {translate("bestPlacesOnEachCity")}
                </p>
              </div>

              <div className="mobile-headline-view">
                <p className="text-center h3">
                  {translate("bestPlacesOnEachCity")}
                </p>
              </div>

              <div className="citys_grid">
                {cities &&
                  cities
                    .filter((item) => item.status !== 0)
                    .map((item, idx) => (
                      <Link
                        href={`properties-on-map/?&city_id=${item.id}&city=${item.name}`}
                        key={idx}
                      >
                        <figure className="flex items-center justify-center">
                          <Image
                            loading="lazy"
                            src={item.image}
                            alt={`best place in ${item.name} city`}
                            fill
                            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                          />
                          <h3 className="text-white z-2 text-[48px] font-semibold">
                            {item.name}
                          </h3>
                        </figure>
                      </Link>
                    ))}
              </div>
            </div>
          </section>
        )}
      </div>

      {showModal && (
        <LoginModal isOpen={showModal} onClose={handleCloseModal} />
      )}
    </Layout>
  );
};

export default HomePage;

import React, { useEffect, useState } from "react";
import { ButtonGroup, Modal } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { BiFilter } from "react-icons/bi";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { translate } from "@/utils";
import LocationSearchBox from "../Location/LocationSearchBox";
import { GrRefresh } from "react-icons/gr";
import { RiCloseCircleLine, RiSendPlane2Line } from "react-icons/ri";
import { useRouter } from "next/router";
import {
  getfilterData,
  categoriesCacheData,
  loadCategories,
} from "@/store/reducer/momentSlice";

const SearchTab = ({ getCategories }) => {
  const router = useRouter();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterD, setFilterD] = useState();
  const [formData, setFormData] = useState({
    propType: "", // Set your default value here
    minPrice: "",
    maxPrice: "",
    postedSince: "",
    selectedLocation: null,
  });
  const Categorydata = useSelector(categoriesCacheData);
  const [activeTab, setActiveTab] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [categoryInput, setCategoryInput] = useState("");
  const [minPriceInput, setMinPriceInput] = useState("");
  const [maxPriceInput, setMaxPriceInput] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const handleHideFilterModal = () => {
    setShowFilterModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Ensure the value is at least 0
    const sanitizedValue = Math.max(parseFloat(value), 0);

    // Update the form data
    setFormData({
      ...formData,
      [name]: sanitizedValue,
    });
  };

  const handlePostedSinceChange = (e) => {
    setFormData({
      ...formData,
      postedSince: e.target.value,
    });
  };

  const handleLocationSelected = (locationData) => {
    setFormData({
      ...formData,
      selectedLocation: locationData,
    });
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab === "sell" ? 0 : 1);
  };
  const handleApplyFilter = () => {
    let postedSinceValue = "";
    if (formData.postedSince === "yesterday") {
      postedSinceValue = "0";
    } else if (formData.postedSince === "lastWeek") {
      postedSinceValue = "1";
    }

    // Include the postedSince value in the filterData object
    const filterData = {
      propType: formData.propType || "", // Set to empty string if not selected
      minPrice: formData.minPrice || "0", // Set to empty string if not selected
      maxPrice: formData.maxPrice !== undefined ? formData.maxPrice : "", // Set to empty string if not selected
      postedSince: postedSinceValue, // Include it here
      selectedLocation: formData.selectedLocation || null, // Set to null if not selected
    };
    // Set the filter data in state
    setFilterD(filterData);
    setShowFilterModal(false); // Close the modal
  };
  useEffect(() => {
    // You can access the updated filterD value here
  }, [filterD]);
  const handleSearch = (e) => {
    e.preventDefault();

    const searchData = {
      filterData: filterD,
      activeTab: activeTab,
      searchInput: searchInput,
      categoryInput: categoryInput,
      minPriceInput: minPriceInput,
      maxPriceInput: maxPriceInput,
    };
    getfilterData(searchData);

    setShowFilterModal(false); // Close the modal

    // Redirect to /search
    router.push(
      `/properties-on-map/?city=${searchData.searchInput}&category=${searchData.activeTab}&min_price=${searchData.minPriceInput}&max_price=${searchData.maxPriceInput}`,
    );
  };
  const [clearfilterLocation, setClearFilerLocation] = useState(false);

  const handleClearFilter = () => {
    setClearFilerLocation(true);
    setFormData({
      propType: "",
      minPrice: "",
      maxPrice: "",
      postedSince: "",
      selectedLocation: null, // Set to null to clear it
    });
  };
  useEffect(() => {
    loadCategories();
  }, []);

  return (
    <div className="z-10 rounded-3xl bg-white sm:w-full lg:w-2/6 px-4 pt-6 pb-0">
      <div className="h-12">
        <h1 className="text-2xl font-semibold">
          {translate("searchProperties")}
        </h1>
      </div>
      <div className="grid grid-cols-1 grid-rows-4 gap-3">
        <div>
          <label className="font-semibold"> {translate("location")}</label>
          <input
            className="border w-full rounded-lg"
            placeholder={translate("searchYourProperty")}
            name="propertySearch"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
        <div>
          <label className="font-semibold"> {translate("propTypes")}</label>
          <select
            id="categories"
            class="bg-white border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            onChange={(e) => setCategoryInput(e.target.value)}
          >
            <option value={""}>{translate("chooseValue")}...</option>
            {Categorydata &&
              Categorydata.map((ele, index) => (
                <option key={index} value={ele?.id}>
                  {ele?.category}
                </option>
              ))}
          </select>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <label className="font-semibold"> {translate("minPrice")}</label>
            <input
              type="number"
              className="border border-gray-300 w-full rounded-lg"
              placeholder={translate("minPrice")}
              name="minPrice"
              value={minPriceInput}
              onChange={(e) => setMinPriceInput(e.target.value)}
            />
          </div>
          <div>
            <label className="font-semibold"> {translate("maxPrice")}</label>
            <input
              type="number"
              className="border border-gray-300 w-full rounded-lg"
              placeholder={translate("maxPrice")}
              name="maxPrice"
              value={maxPriceInput}
              onChange={(e) => setMaxPriceInput(e.target.value)}
            />
          </div>
        </div>
        <div className="my-3">
          <button
            className="button button-solid w-full flex justify-center items-center gap-1.5 py-2.5 rounded-xl"
            onClick={handleSearch}
          >
            {translate("search")}
          </button>
        </div>
      </div>
      <Modal
        show={showFilterModal}
        onHide={handleHideFilterModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="filter-modal"
      >
        <Modal.Header>
          <Modal.Title>{translate("filterProp")}</Modal.Title>
          <RiCloseCircleLine
            className="close-icon"
            size={40}
            onClick={handleHideFilterModal}
          />
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="first-grup">
              <div className="prop-type-modal">
                <span>{translate("propTypes")}</span>
                <select
                  className="form-select"
                  aria-label="Default select"
                  name="propType"
                  value={formData.propType}
                  onChange={handleInputChange}
                >
                  <option value="">{translate("selectPropType")}</option>
                  {/* Add more options as needed */}
                  {getCategories &&
                    getCategories?.map((ele, index) => (
                      <option key={index} value={ele.id}>
                        {ele.category}
                      </option>
                    ))}
                </select>
              </div>

              <div className="prop-location-modal">
                <span>{translate("selectYourLocation")}</span>
                <LocationSearchBox
                  onLocationSelected={handleLocationSelected}
                  clearfilterLocation={clearfilterLocation}
                />
              </div>
            </div>
            <div className="second-grup">
              <div className="budget-price-modal">
                <span>{translate("budget")}</span>
                <div className="budget-inputs">
                  <input
                    className="price-input"
                    type="number"
                    placeholder="Min Price"
                    name="minPrice"
                    value={formData.minPrice}
                    onChange={handleInputChange}
                  />
                  <input
                    className="price-input"
                    type="number"
                    placeholder="Max Price"
                    name="maxPrice"
                    value={formData.maxPrice}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="third-grup">
              <div className="posted-since">
                <span>{translate("postedSince")}</span>
                <div className="posted-duration-modal">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value="anytime"
                      checked={formData.postedSince === "anytime"}
                      onChange={handlePostedSinceChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      {translate("anytime")}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      value="lastWeek"
                      checked={formData.postedSince === "lastWeek"}
                      onChange={handlePostedSinceChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      {translate("lastWeek")}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                      value="yesterday"
                      checked={formData.postedSince === "yesterday"}
                      onChange={handlePostedSinceChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault3"
                    >
                      {translate("yesterday")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="filter-footer">
          <div className="clear-filter-modal" onClick={handleClearFilter}>
            <GrRefresh size={25} />
            <button id="clear-filter-button" type="submit">
              {translate("clearFilter")}
            </button>
          </div>
          <div className="apply-filter-modal" onClick={handleApplyFilter}>
            <RiSendPlane2Line size={25} />
            <button id="apply-filter-button" type="submit">
              {translate("applyFilter")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SearchTab;
